import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import type { OpenAPIV3 } from 'openapi-types';
import { parseOperations } from '../open-api/parse-operations';
import { ApiServerProxyResponse, OperationsFilter } from '../open-api';
import { baseURL } from '../../../../utils/baseUrls';
import { useAuth0 } from '@auth0/auth0-react';
import { useParams } from 'react-router-dom';
import { useApplication } from 'applications/hooks';

export const useApiSchema = (opts?: { filter?: OperationsFilter }) => {
  const { filter } = opts || {};

  const { application } = useApplication();

  const auth = useAuth0();

  const { applicationId, connectionId } = useParams<{
    applicationId: string;
    connectionId: string;
  }>();

  const query = useQuery({
    queryKey: ['api-schema'],
    queryFn: async () => {
      const data = await axios
        .post<ApiServerProxyResponse<OpenAPIV3.Document>>(
          `${baseURL}/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/proxy/open-api`,
          {
            method: 'get',
            path: '/openapi.json',
          },
          {
            headers: {
              Authorization: `Bearer ${await auth.getAccessTokenSilently()}`,
            },
          },
        )
        .then((res) => res.data);

      return data.apiServerResponse.data;
    },
    refetchOnMount: false,
  });

  const parsedSchema = parseOperations({
    spec: query.data,
    filter,
    isSandbox: application?.isSandbox() ?? false,
  });

  return { ...query, ...parsedSchema };
};
