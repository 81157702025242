import { ReactNode, useEffect, useState } from 'react';
import type { OpenAPIV3 } from 'openapi-types';
import {
  Box,
  Button,
  Code,
  HStack,
  Input,
  Select,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  ArraySchemaProperty,
  PRIMITIVE_SCHEMA_TYPE,
  PrimitiveSchemaType,
  JsonSchemaSelections,
  JsonSchemaProperty,
} from '../open-api';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { COLORS } from 'constant';

const useArray = ({
  name,
  type,
}: {
  name: string;
  type:
    | OpenAPIV3.NonArraySchemaObjectType
    | OpenAPIV3.ArraySchemaObjectType
    | 'null';
}) => {
  const { control, setValue, getValues } = useFormContext();

  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  const flatFields = (getValues(name) as Array<PrimitiveSchemaType>) || [];

  const appendFlat = (item: PrimitiveSchemaType | null) => {
    setValue(name, [...flatFields, item]);
  };

  const removeFlat = (index: number) => {
    setValue(
      name,
      flatFields.filter((_, i) => i !== index),
    );
  };

  return PRIMITIVE_SCHEMA_TYPE.includes(type as PrimitiveSchemaType)
    ? {
        fields: flatFields,
        append: appendFlat,
        remove: removeFlat,
      }
    : {
        fields,
        append,
        remove,
      };
};

const Border = ({
  children,
  propertyKey,
  formPath,
  removeBorder,
  deleteField,
  setSchema,
  schemas,
  selectedSchema,
}: {
  children: ReactNode;
  propertyKey?: string;
  formPath: string;
  removeBorder?: boolean;
  deleteField?: () => void;
  schemas: JsonSchemaSelections;
  selectedSchema: JsonSchemaProperty;
  setSchema: (schema: JsonSchemaProperty) => void;
}) => {
  const { setValue, watch } = useFormContext();

  const fieldValue = watch(formPath);

  return (
    <Box
      pos="relative"
      {...(!removeBorder && {
        px: '1',
        pt: '4',
        pb: '1',
        border: '1px solid',
        borderColor: COLORS.GRAY.GRAY_300,
        borderRadius: '5',
      })}
    >
      <HStack
        fontWeight="normal"
        pos="absolute"
        bg="white"
        px="2px"
        fontSize="11px"
        spacing="1"
        top={removeBorder ? '-8px' : '-10px'}
        left="4px"
        display="flex"
        align="center"
        zIndex="2"
      >
        {propertyKey && (
          <Text>
            <Box as="strong">{propertyKey}</Box>
          </Text>
        )}
        {schemas.length > 1 ? (
          <Box
            sx={{
              '.chakra-select__icon-wrapper': { w: 4, right: 0 },
              select: { pr: 4 },
            }}
          >
            <Select
              variant="unstyled"
              border="none"
              size="xs"
              fontSize="11px"
              value={selectedSchema.type}
              iconSize="xs"
              onChange={(e) => {
                const schema = schemas.find(
                  (s) => s.type === (e.target.value as string),
                );

                if (schema) setSchema(schema);
              }}
            >
              {schemas.map((schema) => (
                <option
                  key={schema.type}
                  value={schema.type}
                  onClick={() => setSchema(schema)}
                >
                  {schema.type}
                </option>
              ))}
            </Select>
          </Box>
        ) : (
          <Text>{selectedSchema.type}</Text>
        )}
        {'required' in selectedSchema && selectedSchema.required && (
          <Text color="red.500" fontSize="10px">
            *
          </Text>
        )}
      </HStack>
      {(deleteField || fieldValue !== undefined) && (
        <Button
          size="xs"
          pos="absolute"
          bg="white"
          right="2"
          variant="link"
          fontSize="10px"
          color={COLORS.GRAY.GRAY_500}
          top={removeBorder ? '-6px' : '-8px'}
          onClick={deleteField || (() => setValue(formPath, undefined))}
          zIndex="2"
        >
          Remove
        </Button>
      )}
      {children}
    </Box>
  );
};

const ArrayInput = ({
  propertyKey,
  formPath,
  schema,
}: {
  propertyKey?: string;
  formPath: string;
  schema: ArraySchemaProperty;
}) => {
  const { fields, append, remove } = useArray({
    name: formPath,
    type: schema.items?.[0]?.type || 'string',
  });

  return (
    <Stack>
      {fields.map((field, index) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <JsonBuilder
          key={field && typeof field === 'object' ? field.id : index}
          jsonSchemas={schema.items}
          formPath={`${formPath}.${index}`}
          deleteField={() => remove(index)}
        />
      ))}
      <Button
        onClick={() => append(null)}
        size="xs"
        fontSize="11px"
        variant="outline"
      >
        Add{' '}
        <Code fontSize="11px" mx="2px">
          {propertyKey}
        </Code>{' '}
        item
      </Button>
    </Stack>
  );
};

const NullInput = ({ formPath }: { formPath: string }) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    setValue(formPath, null);
  }, [formPath, setValue]);

  return (
    <Input
      type="text"
      borderColor="gray.400"
      size="xs"
      borderRadius="5"
      readOnly
      value="null"
    />
  );
};

export const JsonBuilder = ({
  jsonSchemas,
  propertyKey,
  formPath,
  deleteField,
}: {
  jsonSchemas: JsonSchemaSelections;
  propertyKey?: string;
  formPath: string;
  deleteField?: () => void;
}) => {
  const { register } = useFormContext();

  const [schema, setSchema] = useState(jsonSchemas[0]);

  if (!schema) {
    return null;
  }

  return (
    <Border
      propertyKey={propertyKey}
      formPath={formPath}
      deleteField={deleteField}
      setSchema={setSchema}
      selectedSchema={schema}
      schemas={jsonSchemas}
      removeBorder={schema.type !== 'object' && schema.type !== 'array'}
    >
      {schema.type === 'object' ? (
        <Stack spacing="4">
          {Object.entries(schema.properties || {}).map(([key, value]) => (
            <JsonBuilder
              key={key}
              jsonSchemas={value}
              propertyKey={key}
              formPath={`${formPath}.${key}`}
            />
          ))}
        </Stack>
      ) : schema.type === 'array' ? (
        <ArrayInput
          propertyKey={propertyKey}
          formPath={formPath}
          schema={schema}
        />
      ) : schema.type === 'boolean' ? (
        <Select
          borderColor="gray.400"
          size="xs"
          borderRadius="5"
          {...register(formPath)}
        >
          {['true', 'false'].map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </Select>
      ) : schema.type === 'string' && schema.options ? (
        <Select
          borderColor="gray.400"
          size="xs"
          borderRadius="5"
          {...register(formPath, { value: schema.options[0] })}
        >
          {schema.options
            .filter((option) => option !== null)
            .map((option) => (
              <option key={option.toString()} value={option.toString()}>
                {option}
              </option>
            ))}
        </Select>
      ) : ['string', 'number', 'integer'].includes(schema.type) ? (
        <Input
          type={schema.type === 'string' ? 'text' : 'number'}
          borderColor="gray.400"
          size="xs"
          borderRadius="5"
          placeholder={`Enter ${propertyKey || 'value'}`}
          {...register(formPath, { value: schema.type === 'number' ? 0 : '' })}
        />
      ) : schema.type === 'null' ? (
        <NullInput formPath={formPath} />
      ) : null}
    </Border>
  );
};
