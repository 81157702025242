import { Stack, Text, Textarea } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

export const RequestPreview = ({ formPath }: { formPath: string }) => {
  const { watch } = useFormContext();

  return (
    <Stack flex="2" pos="relative">
      <Text fontSize="10px" color="gray.700" pos="absolute" right="2" top="1">
        Preview
      </Text>
      <Textarea
        placeholder="{}"
        fontFamily="mono"
        key={formPath}
        fontSize="10px"
        value={JSON.stringify(watch(formPath), null, 2) || ''}
        readOnly
        size="sm"
        flex="1"
      />
    </Stack>
  );
};
