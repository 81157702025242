import {
  ConnectSessionEvent,
  ConnectSessionEventType,
  ConnectSessionErrorEvent,
  ConnectStep,
  ConnectStepStage,
} from 'types/connect';
import { format } from 'shared/utils/dateFormatter';

export interface TimelineStep {
  step: number;
  title: string;
  status: 'Not Started' | 'Completed' | 'Error' | 'In Progress';
  date: Date | null;
  formattedDate: string;
  duration: string;
  errorCount?: number;
}

const formatDuration = (ms: number): string => {
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  return hours > 0 ? `${hours}h ${minutes}m` : `${minutes}m`;
};

const getStepNumber = (step: ConnectStep): number => {
  const stepMap = {
    [ConnectStep.PREAMBLE]: ConnectStepStage.TermsOfService,
    [ConnectStep.PERMISSIONS]: ConnectStepStage.Permissions,
    [ConnectStep.SELECT_PROVIDER]: ConnectStepStage.ProviderSelection,
    [ConnectStep.SIGN_IN]: ConnectStepStage.Authorization,
    [ConnectStep.MANUAL_SIGN_IN]: ConnectStepStage.Authorization,
    [ConnectStep.MFA]: ConnectStepStage.Authorization,
    [ConnectStep.CAPTCHA]: ConnectStepStage.Authorization,
    [ConnectStep.CHOOSE_ACCOUNT]: ConnectStepStage.Authorization,
  };

  return stepMap[step] || ConnectStepStage.Authorization;
};

const getStepTitle = (step: number): string => {
  const titles = [
    'Terms of Service',
    'Permissions',
    'Provider Selection',
    'Authorization',
  ];
  return titles[step - 1] || 'Unknown Step';
};

const findNextKnownStep = (
  stepMap: Record<string, TimelineStep>,
  currentStep: number,
): TimelineStep | null => {
  for (let i = currentStep + 1; i <= 4; i++) {
    const step = stepMap[i];
    if (step) return step;
  }

  return null;
};

const processEvents = (
  events: ConnectSessionEvent[],
): {
  stepMap: Record<string, TimelineStep>;
  authorizationErrors: ConnectSessionErrorEvent[];
  lastReachedStep: number;
  providerSelectTime?: Date;
  sessionCompletedTime?: Date;
} => {
  const stepMap: Record<string, TimelineStep> = {};
  const authorizationErrors: ConnectSessionErrorEvent[] = [];
  let lastReachedStep = 0;
  let providerSelectTime: Date | undefined;
  let sessionCompletedTime: Date | undefined;

  events.forEach((event) => {
    const eventDate = new Date(event.createdAt);
    const step = getStepNumber(event.eventData.step);
    lastReachedStep = Math.max(lastReachedStep, step);

    if (!stepMap[step]) {
      stepMap[step] = {
        step,
        title: getStepTitle(step),
        status: step < 4 ? 'Completed' : 'In Progress',
        date: eventDate,
        formattedDate: format(eventDate),
        duration: '—',
      };
      if (step === 3) providerSelectTime = eventDate;
    }

    if (event.eventType === ConnectSessionEventType.OnError) {
      authorizationErrors.push(event);
    } else if (event.eventType === ConnectSessionEventType.OnSessionCompleted) {
      sessionCompletedTime = eventDate;
      if (stepMap[4]) {
        stepMap[4].date = eventDate;
        stepMap[4].formattedDate = format(eventDate);
      }
    }
  });

  return {
    stepMap,
    authorizationErrors,
    lastReachedStep,
    providerSelectTime,
    sessionCompletedTime,
  };
};

export const generateTimelineSteps = (
  sessionEvents: ConnectSessionEvent[],
): { steps: TimelineStep[]; errorEvents: ConnectSessionErrorEvent[] } => {
  const sortedEvents = [...sessionEvents].sort(
    (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
  );
  const {
    stepMap,
    authorizationErrors,
    lastReachedStep,
    providerSelectTime,
    sessionCompletedTime,
  } = processEvents(sortedEvents);

  // Calculate durations
  const stepNumbers = Object.keys(stepMap)
    .map(Number)
    .sort((a, b) => a - b);

  for (let i = 1; i < stepNumbers.length; i++) {
    const currentStepNumber = stepNumbers[i];
    const prevStepNumber = stepNumbers[i - 1];

    if (!currentStepNumber || !prevStepNumber) continue;

    const currentStep = stepMap[currentStepNumber];
    const prevStep = stepMap[prevStepNumber];

    if (!currentStep || !prevStep) continue;

    if (
      currentStepNumber === ConnectStepStage.ProviderSelection &&
      currentStep.date &&
      prevStep.date &&
      providerSelectTime
    ) {
      currentStep.duration = formatDuration(
        providerSelectTime.getTime() - prevStep.date.getTime(),
      );

      prevStep.duration = formatDuration(
        currentStep.date.getTime() - prevStep.date.getTime(),
      );
    } else if (
      currentStepNumber === ConnectStepStage.Authorization &&
      providerSelectTime &&
      sessionCompletedTime
    ) {
      currentStep.duration = formatDuration(
        sessionCompletedTime.getTime() - providerSelectTime.getTime(),
      );
    } else if (currentStep.date && prevStep.date) {
      prevStep.duration = formatDuration(
        currentStep.date.getTime() - prevStep.date.getTime(),
      );
    }
  }

  // Fill in empty slots and set statuses
  const allSteps: TimelineStep[] = [];
  for (let i = 1; i <= 4; i++) {
    const step = stepMap[i];

    if (step) {
      allSteps.push(step);
    } else {
      const nextKnownStep = findNextKnownStep(stepMap, i);
      const status =
        i === ConnectStepStage.TermsOfService
          ? 'Not Started'
          : i === lastReachedStep + 1 && !nextKnownStep
          ? 'In Progress'
          : nextKnownStep
          ? 'Completed'
          : 'Not Started';

      allSteps.push({
        step: i,
        title: getStepTitle(i),
        status,
        date: nextKnownStep?.date ?? null,
        formattedDate: nextKnownStep?.formattedDate ?? '—',
        duration: nextKnownStep ? '0m' : '—',
      });
    }
  }

  // Handle authorization step
  const authorizationStep = allSteps[ConnectStepStage.Authorization - 1];

  if (authorizationStep) {
    authorizationStep.status = sessionCompletedTime
      ? 'Completed'
      : authorizationErrors.length > 0
      ? 'Error'
      : lastReachedStep >= ConnectStepStage.ProviderSelection
      ? 'In Progress'
      : 'Not Started';
    if (authorizationStep.status === 'Error')
      authorizationStep.errorCount = authorizationErrors.length;
  }

  return { steps: allSteps, errorEvents: authorizationErrors };
};
