import { Box, Code, HStack, Heading, Text } from '@chakra-ui/react';
import { RequestBadge } from './request-badge';
import { useSelectedOperation } from '../store/selected-operation';
import { COLORS } from 'constant';

export const OperationTitle = () => {
  const { selectedOperation } = useSelectedOperation();

  return (
    <>
      <Heading as="h3" size="sm" fontWeight="semibold">
        {selectedOperation?.title}
      </Heading>
      {selectedOperation && (
        <HStack
          border="1px solid"
          borderColor="gray.100"
          borderRadius="5"
          px="1"
        >
          <RequestBadge method={selectedOperation.method} />
          <HStack divider={<Box border="0">/</Box>}>
            {selectedOperation.path.split('/').map((pathPiece) => {
              return (
                <Box
                  as="code"
                  key={pathPiece}
                  fontSize="xs"
                  fontWeight="500"
                  color="gray.700"
                >
                  {/^\{.*\}$/.test(pathPiece) ? (
                    <Code
                      colorScheme="purple"
                      borderRadius="5"
                      border="2px solid"
                      borderColor={COLORS.FINCH.PURPLE}
                      fontSize="xs"
                    >
                      {pathPiece}
                    </Code>
                  ) : (
                    <Text key={pathPiece}>{pathPiece}</Text>
                  )}
                </Box>
              );
            })}
          </HStack>
        </HStack>
      )}
    </>
  );
};
