import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  AccordionPanelProps,
  AccordionProps,
  Heading,
  HStack,
  Tooltip,
} from '@chakra-ui/react';
import { ReactNode, useEffect, useState } from 'react';
import { FiInfo } from 'react-icons/fi';

type CollapsibleProps = AccordionProps & {
  heading: ReactNode;
  children: ReactNode;
  toolTipLabel?: string;
  openByDefault?: boolean;
  spaceBetweenIcon?: boolean;
  panelProps?: AccordionPanelProps;
  fontSize?: string;
  fontWeight?: string;
  isOpen?: boolean;
};
export const Collapsible = ({
  heading,
  children,
  toolTipLabel,
  openByDefault,
  spaceBetweenIcon,
  fontSize = 'md',
  fontWeight = 'normal',
  panelProps,
  isOpen,
  ...accordionProps
}: CollapsibleProps) => {
  const [index, setIndex] = useState(openByDefault ? 0 : -1);

  useEffect(() => {
    if (typeof isOpen === 'boolean') {
      setIndex(isOpen ? 0 : -1);
    }
  }, [isOpen]);

  return (
    <Accordion
      allowToggle
      index={index}
      onChange={(e) => setIndex(e as number)}
      defaultIndex={openByDefault ? [0] : []}
      {...accordionProps}
      p="0"
    >
      <AccordionItem border="none">
        <AccordionButton
          p="1"
          justifyContent={spaceBetweenIcon ? 'space-between' : 'flex-start'}
        >
          <HStack>
            <Heading
              as="h4"
              textAlign="left"
              fontSize={fontSize}
              fontWeight={fontWeight}
            >
              {heading}
            </Heading>
            {toolTipLabel && (
              <Tooltip label={toolTipLabel} hasArrow placement="bottom-start">
                <span>
                  <FiInfo />
                </span>
              </Tooltip>
            )}
          </HStack>
          <AccordionIcon ml="4" fontSize={fontSize} />
        </AccordionButton>

        <AccordionPanel p="0" {...panelProps}>
          {children}
        </AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
