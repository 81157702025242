import { User, useAuth0 } from '@auth0/auth0-react';
import type { Auth0ContextInterface } from '@auth0/auth0-react';

import { getApplication } from './api';
import { datadogRum } from '@datadog/browser-rum';
import { useQuery } from '@tanstack/react-query';
import { Application as ApplicationModel } from './model';
import { AxiosError } from 'axios';
import { ApplicationWithFeatures } from '@finch-api/developer-dashboard-common';
import { useParams } from 'react-router-dom';

export const useApplication = (id?: string) => {
  const { applicationId: paramId } = useParams<{ applicationId: string }>();
  const applicationId = id || paramId;
  const { getAccessTokenSilently, user }: Auth0ContextInterface<User> =
    useAuth0();

  const applicationQuery = useQuery<
    ApplicationWithFeatures | undefined,
    AxiosError
  >({
    queryKey: ['application', applicationId],
    queryFn: async () => {
      const token = await getAccessTokenSilently();
      const application = await getApplication(token, applicationId);
      datadogRum.setGlobalContext({ client: application });
      return application;
    },
    enabled: !!applicationId,
    refetchOnMount: false,
  });

  const application = applicationQuery.data
    ? new ApplicationModel(applicationQuery.data)
    : undefined;

  return {
    ...applicationQuery,
    application,
    user,
  };
};
