import get from 'lodash/get';
import type { OpenAPIV3 } from 'openapi-types';

export const parseParameters = (
  parameters?: OpenAPIV3.OperationObject['parameters'],
  spec?: OpenAPIV3.Document,
) => {
  const headers: object[] = [];
  const queries: object[] = [];
  const paths: object[] = [];

  for (const parameter of parameters || []) {
    let parameterObject = parameter;

    if ('$ref' in parameterObject) {
      const { $ref } = parameterObject;
      const [, ...pathToSchema] = $ref.split('/');
      parameterObject = get(
        spec,
        pathToSchema,
        {},
      ) as OpenAPIV3.ParameterObject;
    }

    const newParam = {
      [parameterObject.name]:
        (parameterObject?.schema as OpenAPIV3.SchemaObject)?.default ||
        (parameterObject?.schema as OpenAPIV3.SchemaObject)?.example ||
        '',
    };

    if (parameterObject.in === 'header') {
      headers.push(newParam);
    }

    if (parameterObject.in === 'query') {
      queries.push(newParam);
    }

    if (parameterObject.in === 'path') {
      paths.push(newParam);
    }
  }

  return {
    headers,
    queries,
    paths,
  };
};
