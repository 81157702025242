import { ReactNode, useState } from 'react';

import { Flex, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { StagedConnectionDetail } from '../types';
import { ConnectionDetailTab, connectionDetailTabs } from '../constants';

import { ConnectSessions } from './ConnectSessions';
import { ConnectionOverviewPanel } from './ConnectionOverviewPanel';
import { JobHistoryPanel } from './JobHistoryPanel';
import { AuthenticationProgressPanel } from '../../connection-detail-v2/components/AuthenticationProgressPanel';
import { ConnectSession, ConnectSessionEvent } from 'types/connect';
import { SessionHistoryTab } from 'connection-detail-v2/constants';
import { AppTab, AppTabList } from 'components/Tabs';

const TAB_TO_COMPONENT: Record<
  ConnectionDetailTab,
  (props: {
    connectionDetail: StagedConnectionDetail;
    connectSessionEvents: ConnectSessionEvent[];
    navigateToSessionEventsTab: () => void;
    selectedSession: ConnectSession | null;
    setSelectedSession: (connectSession: ConnectSession | null) => void;
    activeSessionEventsTab: SessionHistoryTab;
    setActiveSessionEventsTab: (tab: SessionHistoryTab) => void;
  }) => ReactNode
> = {
  [ConnectionDetailTab.OVERVIEW]: ConnectionOverviewPanel,
  [ConnectionDetailTab.JOB_HISTORY]: JobHistoryPanel,
  [ConnectionDetailTab.AUTHENTICATION_PROGRESS]: AuthenticationProgressPanel,
  [ConnectionDetailTab.SESSION_HISTORY]: ConnectSessions,
};

const ConnectionDetailTabs = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  const tabs = Object.entries(connectionDetailTabs);

  const [activeTab, setActiveTab] = useState(ConnectionDetailTab.OVERVIEW);

  const [selectedSession, setSelectedSession] = useState<ConnectSession | null>(
    null,
  );

  const [activeSessionEventsTab, setActiveSessionEventsTab] = useState(
    SessionHistoryTab.Details,
  );

  const latestConnectSession = connectionDetail.connectSession;

  const navigateToSessionEventsTab = () => {
    setActiveTab(ConnectionDetailTab.SESSION_HISTORY);
    setSelectedSession(latestConnectSession);
    setActiveSessionEventsTab(SessionHistoryTab.Events);
  };

  return (
    <Tabs flexGrow={1} index={activeTab} onChange={setActiveTab}>
      <AppTabList gap={6}>
        {tabs.map(([key, value]) => (
          <AppTab key={key}>{value}</AppTab>
        ))}
      </AppTabList>
      <TabPanels>
        {tabs.map(([key]) => (
          <TabPanel key={key} paddingLeft="0" paddingRight="0">
            {TAB_TO_COMPONENT[parseInt(key, 10) as ConnectionDetailTab]({
              connectionDetail,
              navigateToSessionEventsTab,
              selectedSession,
              setSelectedSession,
              connectSessionEvents: connectionDetail.connectSessionEvents,
              activeSessionEventsTab,
              setActiveSessionEventsTab,
            })}
          </TabPanel>
        ))}
      </TabPanels>
    </Tabs>
  );
};

export const StagedConnectionDetailContent = ({
  connectionDetail,
}: {
  connectionDetail: StagedConnectionDetail;
}) => {
  return (
    <Flex
      gap="24px"
      direction={{
        base: 'column',
        lg: 'row',
      }}
      align={{ base: 'stretch', lg: 'flex-start' }}
    >
      <ConnectionDetailTabs connectionDetail={connectionDetail} />
    </Flex>
  );
};
