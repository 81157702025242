import { Tab, TabList, TabListProps, TabProps } from '@chakra-ui/react';
import { COLORS } from '../constant/colors';

export const AppTabList = ({ children, ...props }: TabListProps) => (
  <TabList
    borderBottomWidth="1px"
    borderColor={COLORS.GRAY.GRAY_400}
    overflowX="auto"
    overflowY="hidden"
    {...props}
  >
    {children}
  </TabList>
);

export const AppTab = ({ children, ...props }: TabProps) => (
  <Tab
    _selected={{
      color: COLORS.FINCH.BLACK,
      fontWeight: 'semibold',
      borderColor: COLORS.FINCH.BLACK,
      borderBottomWidth: '2px',
    }}
    fontSize="14px"
    marginBottom="0"
    color={COLORS.GRAY.GRAY_600}
    padding="4px 4px 10px 4px"
    {...props}
  >
    {children}
  </Tab>
);
