import {
  Box,
  Button,
  FormErrorMessage,
  HStack,
  Input,
  SimpleGrid,
  Stack,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { Fragment } from 'react';
import { useFormContext } from 'react-hook-form';
import { RequestOption } from './request-option';
import { JsonBuilder } from './json-builder';
import { RequestPreview } from './request-preview';
import { useSelectedOperation } from '../store/selected-operation';
import { useSelectedJsonMode } from '../store/selected-json-mode';
import { AppTab, AppTabList } from 'components/Tabs';

type RequestProps = {
  fetch: () => void;
  error: Error | null;
  isLoading: boolean;
};

export const Request = ({ fetch, isLoading, error }: RequestProps) => {
  const { register } = useFormContext();
  const { selectedOperation } = useSelectedOperation();
  const { selectedJsonMode, setSelectedJsonMode } = useSelectedJsonMode();

  const handleTabsChange = (index: number) => {
    setSelectedJsonMode(index);
  };

  const formPath = `${selectedOperation?.path}.${selectedOperation?.method}`;

  return (
    <Stack>
      {selectedOperation?.parameters?.headers?.length && (
        <RequestOption title="Headers">
          <SimpleGrid
            columns={2}
            alignItems="center"
            gap="1"
            templateColumns="2fr 3fr"
          >
            {selectedOperation.parameters.headers.map((header) => {
              const [[key, value] = []] = Object.entries(header);
              return (
                <Fragment key={key}>
                  <Text fontSize="xs">{key}</Text>
                  <Input
                    size="xs"
                    {...register(`${formPath}.headers.${key}`)}
                    defaultValue={value}
                    key={`${formPath}.headers.${key}`}
                  />
                </Fragment>
              );
            })}
            <Text fontSize="xs">X-Finch</Text>
            <Input
              size="xs"
              {...register(`${formPath}.headers.x-finch`)}
              key={`${formPath}.headers.x-finch`}
            />
          </SimpleGrid>
        </RequestOption>
      )}
      {selectedOperation?.parameters?.paths?.length && (
        <RequestOption title="Path parameters" openByDefault>
          <SimpleGrid
            columns={2}
            alignItems="center"
            gap="1"
            templateColumns="2fr 3fr"
          >
            {selectedOperation.parameters.paths.map((header) => {
              const [[key, value] = []] = Object.entries(header);
              return (
                <Fragment key={key}>
                  <Text fontSize="xs">{key}</Text>
                  <Input
                    size="xs"
                    {...register(`${formPath}.paths.${key}`)}
                    defaultValue={value}
                    key={`${formPath}.paths.${key}`}
                  />
                </Fragment>
              );
            })}
          </SimpleGrid>
        </RequestOption>
      )}
      {selectedOperation?.parameters?.queries?.length && (
        <RequestOption title="Query Parameters" openByDefault>
          <SimpleGrid
            columns={2}
            alignItems="center"
            gap="1"
            templateColumns="2fr 3fr"
          >
            {selectedOperation.parameters.queries.map((query) => {
              const [[key, value] = []] = Object.entries(query);
              return (
                <Fragment key={key}>
                  <Text fontSize="xs">{key}</Text>
                  <Input
                    size="xs"
                    {...register(`${formPath}.queries.${key}`)}
                    defaultValue={value}
                    key={`${formPath}.queries.${key}`}
                  />
                </Fragment>
              );
            })}
          </SimpleGrid>
        </RequestOption>
      )}
      {(selectedOperation?.requestBody ||
        selectedOperation?.typedRequestBody) && (
        <RequestOption title="Request body" openByDefault>
          <Tabs
            size="sm"
            p="0"
            index={selectedJsonMode}
            onChange={handleTabsChange}
          >
            <AppTabList>
              {selectedOperation.typedRequestBody && (
                <AppTab fontSize="12px" px="12px">
                  JSON builder
                </AppTab>
              )}
              {selectedOperation?.requestBody && (
                <AppTab fontSize="12px" px="12px">
                  Raw JSON
                </AppTab>
              )}
            </AppTabList>
            <TabPanels>
              {selectedOperation.typedRequestBody && (
                <TabPanel px="0">
                  <HStack align="stretch">
                    <Box flex="3">
                      <JsonBuilder
                        key={`${formPath}.typedRequestBody`}
                        formPath={`${formPath}.typedRequestBody`}
                        jsonSchemas={selectedOperation.typedRequestBody}
                      />
                    </Box>
                    <RequestPreview
                      key={`${formPath}.typedRequestBody`}
                      formPath={`${formPath}.typedRequestBody`}
                    />
                  </HStack>
                </TabPanel>
              )}
              {selectedOperation?.requestBody && (
                <TabPanel px="0" py="2">
                  <Textarea
                    placeholder="{}"
                    fontFamily="mono"
                    {...register(`${formPath}.requestBody`)}
                    key={`${formPath}.requestBody`}
                    fontSize="xs"
                    defaultValue={JSON.stringify(
                      selectedOperation.requestBody,
                      null,
                      2,
                    )}
                    size="sm"
                    rows={6}
                  />
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </RequestOption>
      )}
      <Button
        colorScheme="blackAlpha"
        bg="black"
        color="white"
        size="sm"
        onClick={fetch}
        isLoading={isLoading}
      >
        Send Request
      </Button>
      {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </Stack>
  );
};
