import { COLORS } from 'constant';
import { Box, Text } from '@chakra-ui/react';
import { PRODUCT_LABEL_MAP } from 'constant/scopes';

export const ScopeTag = ({
  scope,
  disconnected,
}: {
  scope: string;
  disconnected?: boolean;
}) => {
  const opacity = disconnected ? 0.5 : 1.0;
  return (
    <Box
      opacity={opacity}
      p="2px 6px"
      borderRadius="6px"
      bg={COLORS.GRAY.GRAY_100}
      color={COLORS.GRAY.GRAY_600}
      border={`1px solid ${COLORS.GRAY.GRAY_400}`}
    >
      <Text fontSize="xs" fontWeight="500">
        {PRODUCT_LABEL_MAP.get(scope.replace('employer:', ''))}
      </Text>
    </Box>
  );
};
