import axios from 'axios';
import * as Sentry from '@sentry/react';

import { ConnectionAccountDetail } from './types';
import type { Auth0ContextInterface, User } from '@auth0/auth0-react';
import { JobStatusEntry } from '@finch-api/common/dist/external/dashboard/job-status';

export class ConnectionDetailApi {
  constructor(
    private baseUrl: string,
    private auth: Auth0ContextInterface<User>,
  ) {}

  getToken = async () => {
    const { getAccessTokenSilently } = this.auth;
    return getAccessTokenSilently();
  };

  async getConnectionDetail(
    applicationId: string,
    connectionId: string,
  ): Promise<ConnectionAccountDetail> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get<ConnectionAccountDetail>(
        uri.toString(),
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async disconnect(applicationId: string, connectionId: string): Promise<void> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/disconnect`,
      this.baseUrl,
    );

    try {
      await axios.post(
        uri.toString(),
        {},
        {
          headers: {
            Authorization: `Bearer ${await this.getToken()}`,
          },
        },
      );
      return;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
  }

  async jobs(
    applicationId: string,
    connectionId: string,
  ): Promise<JobStatusEntry[] | null> {
    const uri = new URL(
      `/api/v1/applications/${applicationId}/connection-accounts/${connectionId}/jobs`,
      this.baseUrl,
    );

    try {
      const { data } = await axios.get(uri.toString(), {
        headers: {
          Authorization: `Bearer ${await this.getToken()}`,
        },
      });
      return data;
    } catch (error) {
      Sentry.captureException(error);
      throw error;
    }
    return null;
  }
}
