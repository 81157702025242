import { Code, Heading, HStack, Stack, Tag, Text } from '@chakra-ui/react';
import ReactJson from '@microlink/react-json-view';
import { COLORS } from 'constant';
import { getStatusCodeColor } from '../utils/get-badge-color';
import { ApiServerProxyResponse } from '../open-api';

type ResponseProps = {
  serverResponse?: ApiServerProxyResponse['apiServerResponse'];
  numberOfOutboundRequests?: number;
  requestId: string;
};

export const Response = ({ serverResponse }: ResponseProps) => {
  const apiServerResponse = serverResponse?.data;
  const responseCode = serverResponse?.status;

  return (
    <Stack flex="1">
      <HStack justify="space-between">
        <Heading as="h3" size="sm">
          Response
        </Heading>
        {responseCode && (
          <HStack align="center" justify="flex-start" spacing="1">
            <Text fontSize="sm" color="gray.600">
              Status:{' '}
            </Text>
            <Tag
              colorScheme={getStatusCodeColor(responseCode)}
              size="sm"
              fontWeight="bold"
              lineHeight="1"
            >
              {responseCode}
            </Tag>
          </HStack>
        )}
      </HStack>

      <Stack pos="relative" overflow="auto" h="100%" minH="65vh">
        <Stack
          bg={COLORS.GRAY.GRAY_100}
          flex="1"
          overflow="auto"
          h="100%"
          borderRadius="5px"
        >
          <Code p="3" fontSize="xs" bg={COLORS.GRAY.GRAY_100}>
            {!!apiServerResponse &&
              (typeof apiServerResponse === 'object' ? (
                <ReactJson
                  src={apiServerResponse}
                  displayObjectSize={false}
                  displayDataTypes={false}
                  enableClipboard={(copy) => {
                    // strip the quotes wrapping the text
                    navigator.clipboard.writeText(
                      JSON.stringify(copy.src, null, 2).replace(
                        /^"(.*)"$/,
                        '$1',
                      ),
                    );
                  }}
                />
              ) : (
                (apiServerResponse as string)
              ))}
          </Code>
        </Stack>
      </Stack>
    </Stack>
  );
};
