import { ReactNode } from 'react';
import { Collapsible } from './collapsible';
import { COLORS } from 'constant';

export const RequestOption = ({
  children,
  title,
  openByDefault,
  isOpen,
}: {
  title: ReactNode;
  isOpen?: boolean;
  children: ReactNode;
  openByDefault?: boolean;
}) => {
  return (
    <Collapsible
      heading={title}
      isOpen={isOpen}
      openByDefault={openByDefault}
      spaceBetweenIcon
      fontSize="sm"
      border="1px"
      borderColor="gray.200"
      bg={COLORS.GRAY.GRAY_100}
      borderRadius="5"
      px="1"
      panelProps={{ bg: 'white', p: '2', borderBottomRadius: '5' }}
    >
      {children}
    </Collapsible>
  );
};
