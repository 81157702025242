import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Image,
  Flex,
  ListItem,
  UnorderedList,
  Text,
  Stack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { ExternalLink } from '../shared/ExternalLink';
import image from './clear_acf_release.png';

export const hasSeenReleaseModalName = 'hasSeenClearAcfReleaseModal';

const setSeenModalInLocalStorage = () => {
  localStorage.setItem(hasSeenReleaseModalName, 'true');
};

export const LogsReleaseModal = () => {
  const [shouldOpenModal, setShouldOpenModal] = useState(false);
  useEffect(() => {
    const hasSeenModalInLocalStorage = localStorage.getItem(
      hasSeenReleaseModalName,
    );
    if (!hasSeenModalInLocalStorage) {
      setShouldOpenModal(true);
    }
  }, []);

  return (
    <Modal
      onClose={() => {
        setSeenModalInLocalStorage();
        setShouldOpenModal(false);
      }}
      isOpen={shouldOpenModal}
      isCentered
      size="lg"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {/* <span role="img" aria-label="party popper">
            🎉
          </span>{' '} */}
          New! Connection Health Monitoring Improvements{' '}
          <span role="img" aria-label="party popper">
            🎉
          </span>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Stack spacing="3">
            <Flex justifyContent="center">
              {/* NOTE: ideal dimensions are 930 × 600 */}
              <Image src={image} />
            </Flex>
            <Text>
              We’ve made some updates to the Connections page to help you
              monitor the health of connections.
            </Text>
            <UnorderedList>
              <ListItem>
                See our <strong>Connection Status</strong> field to see if a
                connection requires reauthentication, has insufficient
                permissions, has no account set up (no admin), or has multiple
                errors.
              </ListItem>
              <ListItem>
                Filter by <strong>Connection Status</strong> or{' '}
                <strong>Last Synced</strong> to triage issues and identify stale
                connections.
              </ListItem>
              <ListItem>
                Monitor the setup process for assisted connections by clicking
                into the connection and selecting{' '}
                <strong>Assisted Setup Status</strong>
              </ListItem>
            </UnorderedList>
          </Stack>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="secondary"
            onClick={() => {
              setSeenModalInLocalStorage();
              setShouldOpenModal(false);
            }}
          >
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
