import { Badge } from '@chakra-ui/react';
import { RequestMethod } from '../open-api';

export const COLOR_SCHEME = {
  get: 'green',
  post: 'blue',
  put: 'yellow',
  delete: 'red',
  options: 'gray',
  head: 'gray',
  patch: 'purple',
  trace: 'gray',
} as const;

export const METHOD_LABEL: Record<RequestMethod, string> = {
  get: 'Get',
  post: 'Post',
  put: 'Put',
  delete: 'Del',
  options: 'Options',
  head: 'Head',
  patch: 'Patch',
  trace: 'Trace',
} as const;

export const RequestBadge = ({ method }: { method: RequestMethod }) => {
  return (
    <Badge
      fontSize="10px"
      size="xs"
      p="2px 6px"
      mr="2"
      flexBasis="23%"
      borderRadius="3"
      maxW="2.5rem"
      textAlign="center"
      colorScheme={COLOR_SCHEME[method]}
    >
      {METHOD_LABEL[method] || method}
    </Badge>
  );
};
